import React, { Component } from 'react'
import classes from './Backdrop.module.scss'

/**
 * Backdrop component
 **/

class Backdrop extends Component {
  componentDidMount () {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount () {
    document.body.style.overflow = 'auto'
  }

  render () {
    return (
      <div className={classes.Backdrop} onClick={this.props.onClick} />
    )
  }
}

export default Backdrop
