// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-article-js": () => import("/opt/build/repo/src/templates/single-article.js" /* webpackChunkName: "component---src-templates-single-article-js" */),
  "component---src-general-new-blog-js": () => import("/opt/build/repo/src/general/new-blog.js" /* webpackChunkName: "component---src-general-new-blog-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-amp-js": () => import("/opt/build/repo/src/pages/index.amp.js" /* webpackChunkName: "component---src-pages-index-amp-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("/opt/build/repo/src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-technologies-js": () => import("/opt/build/repo/src/pages/technologies.js" /* webpackChunkName: "component---src-pages-technologies-js" */)
}

