import React from 'react'
import { Link } from 'gatsby'

import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Logo from './UI/Logo/Logo'
import logoImg from '../assets/footer-logo.svg'
import Linkedin from '../assets/icons/linkedin.svg'
import Location from '../assets/icons/location.svg'

const Footer = () => (
  <footer className="footer">
    <div className="footer-wrapper wrapper">
      <div className="top-footer">
        <div className="top-footer-left">
          <div className="contact-us-link-container">
            <h3 className="footer-heading">Have a new project ?</h3>
            <div className="button-wrapper">
              <Link
                to="/contact-us/"
                className="button-link contact-us-link"
              >
              Let’s work Together
              </Link>
            </div>
          </div>
          <div className="locations-wrapper">
            <h3 className="footer-heading">Address</h3>
            <div className="locations">
              <div className="location">
                <Location />
                <div>
                  <p>Valova 13</p>
                  <p>Lviv, Ukraine</p>
                </div>
              </div>
              <div className="location">
                <Location />
                <div>
                  <p>Kooli tn 34</p>
                  <p>Tartu linn, Estonia</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-footer-middle">
          <h3 className="footer-heading">Services</h3>
          <ul>
            <li>Software Product Development</li>
            <li>Dedicated Development Team</li>
            <li>Startups Program</li>
            <li>DevOps as a Service</li>
            <li>Data Science</li>
            <li>UX/UI design</li>
            <li>Quality Assurance & Automation</li>
            <li>Blockchain Outsourcing</li>
          </ul>
        </div>
        <div className="top-footer-right">
          <h3 className="footer-heading">Technologies</h3>
          <ul>
            <li>Java</li>
            <li>React JS</li>
            <li>Angular JS</li>
            <li>.Net</li>
            <li>Scala</li>
            <li>DevOps</li>
            <li>Blockchain</li>
            <li>Data Science</li>
          </ul>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="logo-wrapper">
          <Logo img={logoImg} />
        </div>
        <div className="copyright">
          <span>Copyright © 2019 Bluepes. All rights reserved.</span>
        </div>
        <ul className="social-network">
          <li>
            <OutboundLink
              href='https://www.linkedin.com/company/bluepes/about/'
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </OutboundLink>
          </li>
        </ul>
      </div>

    </div>
  </footer>
)

export default Footer
