import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'

import MenuToggle from './MenuToggle/MenuToggle'
import Drawer from './Drawer/Drawer'
import Backdrop from '../UI/Backdrop/Backdrop'
import Logo from '../UI/Logo/Logo'
import logo from '../../assets/logo.svg'

class Navigation extends Component {
  state = {
    menu: false,
    prevScrollPos: null,
    visible: true
  }

  // Adds an event listener when the component is mount.
  componentDidMount () {
    this.setState({ prevScrollPos: window.pageYOffset })
    window.addEventListener('scroll', this.scrollHandler)
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount () {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  toggleMenuHandler = () => {
    this.setState({
      menu: !this.state.menu
    })
  }

  closeMenuHandler = () => {
    this.setState({
      menu: false
    })
  }

  // Hide or show the menu.
  scrollHandler = () => {
    const { prevScrollPos } = this.state
    const currentScrollPos = window.pageYOffset

    if (prevScrollPos > 0 || currentScrollPos > 91) {
      const visible = prevScrollPos > currentScrollPos || currentScrollPos <= 0

      this.setState({
        prevScrollPos: currentScrollPos,
        visible
      })
    }
  };

  scrollToSection = type => {
    const sections = {
      services: document.querySelector('#services'),
      technologies: document.querySelector('#technologies'),
      industries: document.querySelector('#industries'),
      blog: document.querySelector('#blog')
    }

    window.scroll({
      left: 0,
      top: sections[type].offsetTop - 70,
      behavior: 'smooth'
    })
  }

  scrollToSectionHandler = (event, type) => {
    event.preventDefault()
    if (window.location.pathname === '/') {
      this.scrollToSection(type)
    }

    if (window.location.pathname.match(/\/blog|\/contact-us\/.*/)) {
      navigate('/')
      setTimeout(() => {
        this.scrollToSection(type)
      }, 300)
    }
  }

  render () {
    const { visible } = this.state

    let cls = ['navigation']
    if (!visible) {
      cls.push('hidden')
    }

    return (
      <React.Fragment>
        <div className={cls.join(' ')}>
          <div className="navigation-wrapper">
            <Logo img={logo}/>
            <ul>
              <li>
                <a
                  // activeClassName="active"
                  onClick={e => this.scrollToSectionHandler(e, 'services')}
                  // to="/services/"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  // activeClassName="active"
                  onClick={e => this.scrollToSectionHandler(e, 'industries')}
                  // to="/industries/"
                >
                  Industries
                </a>
              </li>
              <li>
                <a
                  // activeClassName="active"
                  onClick={e => this.scrollToSectionHandler(e, 'technologies')}
                  // to="/technologies/"
                >
                  Technologies
                </a>
              </li>
              <li>
                <a
                  // activeClassName="active"
                  onClick={e => this.scrollToSectionHandler(e, 'blog')}
                  // to="/blog/"
                >
                  Blog
                </a>
              </li>
              <li
                className="button-link"
              >
                <Link
                  className="contact-us-link"
                  to="/contact-us/">
               Contact us
                </Link>
              </li>
            </ul>
          </div>
          {!this.state.menu
            ? <MenuToggle
              onToggle={this.toggleMenuHandler}
              isOpen={this.state.menu}
            />
            : null
          }
        </div>
        <Drawer
          isOpen={this.state.menu}
          onClose={this.closeMenuHandler}
          click={this.scrollToSectionHandler}
        />
        { this.state.menu ? <Backdrop onClick={this.closeMenuHandler} /> : null }
      </React.Fragment>
    )
  }
}

export default Navigation
