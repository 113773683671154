import React from 'react'
import { Link } from 'gatsby'

import classes from './Drawer.module.scss'
import MenuToggle from '../MenuToggle/MenuToggle'

/**
 * Drawer component
 **/

const Drawer = props => {
  const cls = [classes.Drawer]
  if (!props.isOpen) {
    cls.push(classes.Close)
  }

  return (
    <nav className={cls.join(' ')}>
      <MenuToggle
        onToggle={props.onClose}
        isOpen={props.isOpen} />
      <ul>
        <li
          onClick={props.onClose}>
          <a
            // activeClassName="active"
            onClick={e => props.click(e, 'services')}
            // to="/services/"
          >
            Services
          </a>
        </li>
        <li
          onClick={props.onClose}>
          <a
            // activeClassName="active"
            onClick={e => props.click(e, 'industries')}
            // to="/industries/"
          >
            Industries
          </a>
        </li>
        <li
          onClick={props.onClose}>
          <a
            // activeClassName="active"
            onClick={e => props.click(e, 'technologies')}
            // to="/technologies/"
          >
            Technologies
          </a>
        </li>
        <li
          onClick={props.onClose}>
          <a
            // activeClassName="active"
            onClick={e => props.click(e, 'blog')}
            // to="/blog/"
          >
            Blog
          </a>
        </li>
        <li
          className={classes.DrawerButtonLink}
          onClick={props.onClose}>
          <Link
            className="contact-us-link"
            to="/contact-us/">
         Contact us
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Drawer
